<template>
  <div>
    <div class="listwork">
      <div class="listwork__top">
        <h4>Danh sách các hoạt động tư vấn</h4>
      </div>
      <div class="listwork__table__01">
        <vue-good-table
          :selectOptions="{ enabled: true }"
          :columns="columns"
          :rows="dataInvalid"
          style-class="vgt-table"
        ></vue-good-table>
      </div>
      <div class="listwork__text__center">
        <p>Tổng cộng: {{ dataInvalid.length }} hoạt động tư vấn</p>

        <!-- {{ dataInvalid.length }} -->
      </div>
      <div class="listwork__head__02">
        <div class="listwork__head__02__left">
          <h4>Danh sách các hoạt động tư vấn không hợp lệ</h4>

        </div>
        <div class="listwork__head__02__right">
          <b-button
            v-b-tooltip.hover.top="'Chỉnh sửa'"
            variant="primary"
            class="btn-icon"
            @click="onOffEdit"
          >
            <feather-icon icon="EditIcon" />
          </b-button>

          <div class="listwork__head__02__right__icon">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.top="'Kiểm tra'"
              variant="outline-primary"
              class="btn-icon"
              @click="CheckdataNoInvalid"
            >
              <feather-icon icon="CheckCircleIcon" />
            </b-button>
          </div>
        </div>
      </div>
      <div class="listwork__table__02">
        <vue-good-table
          :fixed-header="true"
          :columns="columns02"
          :rows="dataNoInvalid"
          :select-options="{ enabled: false}"
          style-class="vgt-table"
        >

          <template
            slot="table-row"
            slot-scope="props"
          >
            <!--======================= Thêm icon lỗi =================================== -->
            <span
              v-if="props.column.field == 'icon02'"
              v-b-tooltip.hover.right.html="props.row.messErr[0].message"
            >
              <feather-icon
                icon="AlertTriangleIcon"
                color="red"
              />
            </span>
            <!--======================= Color cho vị trí bị lỗi =================================== -->
            <!-- ======================Người tư vấn ===================================-->
            <span
              v-if="props.column.field == 'counselor' && editvalueErr === false "
              :class="{text_danger:props.row.messErr[0].location === 'Counselor'||!props.row.counselor,dataErr:!props.row.counselor,}"
            >
              {{ props.row.counselor }}
            </span>
            <span v-if="props.column.field == 'counselor' && editvalueErr === true ">
              <v-select
                v-if="props.row.messErr[0].location === 'Counselor'"
                v-model="props.row.counselor"
                :reduce="e => e.fullNameId"
                label="fullNameId"
                :options="comboboxCounselor"
                placeholder="Người tư vấn"
                @input="changeCellvalue(props.row.counselor, 'counselor', props.row)"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <span
                v-if="props.row.messErr[0].location !== 'Counselor'"
              >{{ props.row.counselor }}</span>
            </span>
            <!-- ======================Người tư vấn ===================================-->
            <span
              v-if="props.column.field == 'dateAdvise' && editvalueErr === false "
              :class="{text_danger:props.row.messErr[0].location === 'DateAdvise'||!props.row.dateAdvise,dataErr:!props.row.dateAdvise,}"
            >
              {{ props.row.dateAdvise }}
            </span>
            <span v-if="props.column.field == 'dateAdvise' && editvalueErr === true ">
              <input
                v-if="props.row.messErr[0].location === 'DateAdvise'"
                v-model="props.row.dateAdvise"
                type="text"
                class="cssInput"
                @input="changeCellvalue(props.row.dateAdvise, 'dateAdvise', props.row)"
              />
              <span
                v-if="props.row.messErr[0].location !== 'DateAdvise'"
              >{{ props.row.dateAdvise }}</span>
            </span>
            <!-- ======================Ngày tư vấn ===================================-->

            <!-- ======================Ngày sinh ===================================-->
            <span
              v-if="props.column.field == 'birthDay' && editvalueErr === false "
              :class="{text_danger:props.row.messErr[0].location === 'BirthDay'||!props.row.birthDay,dataErr:!props.row.birthDay,}"
            >
              {{ props.row.birthDay }}
            </span>
            <span v-if="props.column.field == 'birthDay' && editvalueErr === true ">
              <input
                v-if="props.row.messErr[0].location === 'BirthDay'"
                v-model="props.row.birthDay"
                type="text"
                class="cssInput"
                @input="changeCellvalue(props.row.birthDay, 'birthDay', props.row)"
              />
              <span
                v-if="props.row.messErr[0].location !== 'BirthDay'"
              >{{ props.row.birthDay }}</span>
            </span>
            <!-- ======================Ngày sinh ===================================-->

            <!-- ======================Ngày sinh ===================================-->
            <span
              v-if="props.column.field == 'gender' && editvalueErr === false "
              :class="{text_danger:props.row.messErr[0].location === 'Gender'||!props.row.gender,dataErr:!props.row.gender,}"
            >
              {{ props.row.gender }}
            </span>
            <span v-if="props.column.field == 'gender' && editvalueErr === true ">
              <v-select
                v-if="props.row.messErr[0].location === 'Gender'"
                v-model="props.row.gender"
                :reduce="e => e"
                :options="genderList"
                placeholder="Người tư vấn"
                @input="changeCellvalue(props.row.gender, 'gender', props.row)"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <span
                v-if="props.row.messErr[0].location !== 'Gender'"
              >{{ props.row.gender }}</span>
            </span>
            <!-- ======================Ngày sinh ===================================-->
            <span
              v-if="props.column.field == 'academicLevel' && editvalueErr === false"
              :class="{text_danger:props.row.messErr[0].location === 'AcademicLevel'||!props.row.academicLevel,dataErr:!props.row.academicLevel,}"
            >
              {{ props.row.academicLevel }}
            </span>
            <span v-if="props.column.field == 'academicLevel' && editvalueErr === true ">
              <v-select
                v-if="props.row.messErr[0].location === 'AcademicLevel'"
                v-model="props.row.academicLevel"
                :reduce="e => e.label"
                label="label"
                :options="optionsSelect || []"
                @input="changeCellvalue(props.row.academicLevel, 'academicLevel', props.row)"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <span
                v-if="props.row.messErr[0].location !== 'AcademicLevel'"
              >{{ props.row.academicLevel }}</span>
            </span>
            <!-- ======================Ngày tư vấn ===================================-->

            <!-- ======================Tên người lao động ===================================-->
            <span
              v-if="props.column.field == 'name' && editvalueErr === false"
              :class="{text_danger:props.row.messErr[0].location === 'Name'||!props.row.name,dataErr:!props.row.name,}"
            >
              {{ props.row.name }}
            </span>
            <span v-if="props.column.field == 'name' && editvalueErr === true ">
              <input
                v-if="props.row.messErr[0].location === 'Name'"
                v-model="props.row.name"
                type="text"
                class="cssInput"
                @input="changeCellvalue(props.row.name, 'name', props.row)"
              >
              <span
                v-if="props.row.messErr[0].location !== 'Name'"
              >{{ props.row.name }}</span>
            </span>
            <!-- ======================Tên người lao động ===================================-->
            <span
              v-if="props.column.field == 'phoneNumber'"
            >
              {{ props.row.phoneNumber }}
            </span>
            <span
              v-if="props.column.field == 'address'"
            >
              {{ props.row.address }}
            </span>
            <span
              v-if="props.column.field == 'content'"
            >
              {{ props.row.content }}
            </span>
          </template>
        </vue-good-table>
      </div>
      <div class="listwork__text__center">
        <p>Tổng cộng: {{ dataNoInvalid.length }} hoạt động tư vấn</p>
      </div>
      <div class="listwork__button">
        <div class="listwork__button__item">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="addClassifyHealthy()"
          >
            Thêm hoạt động tư vấn
          </b-button>
        </div>
        <div class="listwork__button__item">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="close()"
          >
            Hủy bỏ
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BButton, VBTooltip } from 'bootstrap-vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Ripple from 'vue-ripple-directive'
import VSelect from 'vue-select'
import store from '../../store'
import VueGoodTable from '@/components/table/VueGoodTable.vue'

export default {
  components: {
    VSelect,
    VueGoodTable,
    BButton,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      disableProp: false,
      dataInvalid: [],
      dataNoInvalid: [],
      columns: [
        {
          label: 'NGƯỜI TƯ VẤN',
          field: 'counselor',
          sortable: false,
        },
        {
          label: 'NGÀY TƯ VẤN',
          field: 'dateAdvise',
          sortable: false,
        },
        {
          label: 'HỌ TÊN',
          field: 'name',
          sortable: false,
        },
        {
          label: 'NGÀY SINH',
          field: 'birthDay',
          sortable: false,
        },
        {
          label: 'GIỚI TÍNH',
          field: 'gender',
          sortable: false,
        },
        {
          label: 'ĐỊA CHỈ',
          field: 'address',
          sortable: false,
        },
        {
          label: 'TRÌNH ĐỘ HỌC VẤN',
          field: 'academicLevel',
          sortable: false,
        },
        {
          label: 'SỐ ĐIỆN THOẠI',
          field: 'phoneNumber',
          sortable: false,
        },
        {
          label: 'NỘI DUNG TƯ VẤN',
          field: 'content',
          sortable: false,
        },
      ],
      columns02: [
        {
          label: '',
          field: 'icon02',
        },
        {
          label: 'NGƯỜI TƯ VẤN',
          field: 'counselor',
          sortable: false,
        },
        {
          label: 'NGÀY TƯ VẤN',
          field: 'dateAdvise',
          sortable: false,
        },
        {
          label: 'HỌ TÊN',
          field: 'name',
          sortable: false,
        },
        {
          label: 'NGÀY SINH',
          field: 'birthDay',
          sortable: false,
        },
        {
          label: 'GIỚI TÍNH',
          field: 'gender',
          sortable: false,
        },
        {
          label: 'ĐỊA CHỈ',
          field: 'address',
          sortable: false,
        },
        {
          label: 'TRÌNH ĐỘ HỌC VẤN',
          field: 'academicLevel',
          sortable: false,
        },
        {
          label: 'SỐ ĐIỆN THOẠI',
          field: 'phoneNumber',
          sortable: false,
        },
        {
          label: 'NỘI DUNG TƯ VẤN',
          field: 'content',
          sortable: false,
        },
      ],
      comboboxCounselor: [],
      editvalueErr: false, // bật tắt chỉnh sửa
      genderList: [
        'Nam',
        'Nữ',
      ],
      optionsSelect: [
        {
          label: 'Đại học',
          value: 'University',
        },
        {
          label: 'Cao đẳng',
          value: 'College',
        },
        {
          label: 'Trung cấp',
          value: 'Intermediate',
        },
        {
          label: 'Sơ cấp nghề',
          value: 'Primary',
        },
        {
          label: 'Chưa qua đào tạo',
          value: 'None',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('employee', ['validWork', 'validNoWork', 'dataTooltip']),
  },
  created() {
    this.dataInvalid = this.validWork
    this.dataNoInvalid = this.validNoWork
    this.checkErrorUser(this.dataTooltip)
    this.getComboboxCounselor()
  },
  methods: {
    ...mapActions('employee', ['getApiExcelEmployee']),
    async getComboboxCounselor() {
      const { data } = await store.comboboxCounselor()
      data.forEach(e => {
        e.fullNameId = `${e.code}-${e.name}`
      })
      this.comboboxCounselor = data
    },
    // BẬT tắt chỉnh sửa
    onOffEdit() {
      this.editvalueErr = !this.editvalueErr
    },
    // thay đổi dữ liệu trên bảng
    changeCellvalue(event, field, rowData) {
      this.dataNoInvalid[rowData.originalIndex][field] = event
    },
    // nút check
    async CheckdataNoInvalid() {
      const model = {
        listExcel: this.dataNoInvalid,
        isValidate: true,
      }
      const valuedata = await this.getApiExcelEmployee(model)
      const dataInvalid01 = valuedata.data.data.filter(x => x.isSuccess === true)
      this.dataInvalid = this.dataInvalid.concat(dataInvalid01)
      const dataNoInvalid01 = valuedata.data.data.filter(x => x.isSuccess === false)
      if (dataNoInvalid01.length > 0) {
        this.dataNoInvalid = dataNoInvalid01
        this.checkErrorUser(this.dataNoInvalid)
      } else {
        this.dataNoInvalid = []
      }
    },
    // nút hủy bỏ
    close() {
      this.dataInvalid = []
      this.dataNoInvalid = []
      this.$router.push({ name: 'advise-employment' })
    },
    // nút thêm
    // eslint-disable-next-line consistent-return
    async addClassifyHealthy() {
      if (this.dataInvalid.length > 0) {
        const model = {
          listExcel: this.dataInvalid,
          isValidate: false,
        }
        this.getApiExcelEmployee(model).then(res => {
          if (res.status === 200) {
            this.$root.$bvToast.toast('Thêm thành công', {
              title: 'Thông báo',
              variant: 'success',
              toaster: this.$toastPosition,
              solid: true,
            })
            this.dataInvalid = []
            if (this.dataNoInvalid.length === 0) {
              this.$router.push({ name: 'advise-employment' })
            }
          } else {
            this.$root.$bvToast.toast('Thêm không thành công', {
              title: 'Thông báo',
              variant: 'danger',
              toaster: this.$toastPosition,
              solid: true,
            })
          }
        })
      } else {
        this.$root.$bvToast.toast('Thêm không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      }
    },
    checkErrorUser(data) {
      // kiểm tra lỗi
      if (data && data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          const element = data[i]
          if (element.isSuccess === false) {
            /* eslint no-loop-func: "error" */
            let messageError = ''
            if (element.messErr) {
              element.messErr.forEach(err => {
                messageError += `${this.$t(`${err.message}`)} <br> `
              })
            }
            data[i].messageError = messageError
          } else { element.isSelected = true }
        }
      }
    },
  },
}
</script>

<style>
.listwork{
background: white;
box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
border-radius: 6px;

}

.listwork__top{
padding:20px;}
.listwork__top h4{
font-family: Montserrat;
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 22px;

color: #181F28
}
.listwork__text__center p{
font-family: Montserrat;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 21px;
text-align: right;
margin-top:20px;
padding-right:20px;

}
.listwork__head__02{
display: flex;
align-items: center;
justify-content: space-between;
padding:20px;
}
.listwork__head__02__left h4{
font-family: Montserrat;
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 22px;
color: #000000;
}
.listwork__head__02__right{
display: flex;
align-items: center;}
.listwork__head__02__right__icon{
margin-left: 20px;}

.listwork__button{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top:40px;
  padding-right: 20px;
  padding-bottom: 20px;
}
.listwork__button__item{
  margin-left:20px
}
.table-listwork{
    width: 100%;
}
.table-listwork thead tr th{
  background-color: rgba(34, 127, 244, 0.12);
padding-left:10px ;
}

.table-listwork thead tr{
  height: 39px;
  background-color: transparent;
}
.table-listwork  tbody tr{
  height: 58px;
  border-bottom: 1px solid #CBCDD2;

}
.table-listwork  tbody tr th{
 padding-left:10px ;
}
.table-listwork  tbody tr td{
 padding-left:10px ;
}
.text_danger{
  color:red
}
.cssInput{
  outline: none;
  border: 0px;
  border-bottom: 0.2px solid gray;
}
.style-code123{
  padding-right: 20px ;
}
.dataErr::before{
  content: 'Không được để trống';
  color:red,
}
</style>
